import React from "react";
import {
    Box,
    Typography,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Link,
} from "@mui/material";
import { indigo } from "@mui/material/colors";

// Список данных для таблицы
const data = [
    {
        name: "Rust",
        repoName: "Rust-Pumping",
        language: "rust",
        framework: "-",
        taskName: "0_demo",
    },
    {
        name: "Rust",
        repoName: "Rust-Pumping",
        language: "-",
        framework: "rustc",
        taskName: "0_demo_rustc",
    },
    {
        name: "Linux",
        repoName: "Linux-Pumping",
        language: "bash",
        framework: "-",
        taskName: "0_demo",
    },
    {
        name: "Java",
        repoName: "Java-Pumping",
        language: "java",
        framework: "-",
        taskName: "0_demo",
    },
    {
        name: "Java",
        repoName: "Java-Pumping",
        language: "-",
        framework: "maven",
        taskName: "0_demo_maven",
    },
    {
        name: "Rust",
        repoName: "Rust-Pumping",
        language: "-",
        framework: "cargo_test",
        taskName: "0_demo_cargo_test",
    },
    {
        name: "Playwright",
        repoName: "Playwright-Pumping",
        language: "-",
        framework: "playwright",
        taskName: "0_demo",
    },
    {
        name: "ML",
        repoName: "ML-Pumping",
        language: "python",
        framework: "-",
        taskName: "0_demo",
    },
    {
        name: "ML",
        repoName: "ML-Pumping",
        language: "-",
        framework: "python_multi",
        taskName: "0_demo_python_multi",
    },
    {
        name: "HighLoad",
        repoName: "HighLoad-Pumping",
        language: "auto",
        framework: "-",
        taskName: "0_demo",
    },
];

const HomePage: React.FC = () => {
    return (
        <>
            {/* Header */}
            <Box
                sx={{
                    backgroundColor: indigo[500],
                    color: "white",
                    padding: "50px 20px",
                    textAlign: "center",
                    boxShadow: 3,
                }}
            >
                <Container maxWidth="md">
                    <Typography variant="h3" component="h1" gutterBottom>
                        Тренажерка от Инженерки
                    </Typography>
                    <Typography variant="h6" color={indigo[50]}>
                        Прокачай свою айтишную мышцу
                    </Typography>
                </Container>
            </Box>

            {/* Content */}
            <Container maxWidth="md" sx={{ marginY: 4 }}>
                <Typography variant="h5" component="h1" gutterBottom>
                    Демо-задания
                </Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="demo table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Проект</TableCell>
                                <TableCell>Язык</TableCell>
                                <TableCell>Фреймворк</TableCell>
                                <TableCell>Демо</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Link
                                            href={`https://github.com/Inzhenerka/${row.repoName}`}
                                            underline="hover"
                                        >
                                            {row.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>{row.language}</TableCell>
                                    <TableCell>{row.framework}</TableCell>
                                    <TableCell>
                                        <Link
                                            href={`/?repo_name=${row.repoName}&task_name=${row.taskName}`}
                                            underline="hover"
                                        >
                                            {row.taskName}
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </>
    );
};

export default HomePage;
