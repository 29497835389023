import React, { useEffect, useState } from "react";
import { Container, Box, CircularProgress, Alert } from "@mui/material";
import IDE from "./IDE";
import GPTChat from "./GPTChat";
import {
    PumpingTask,
    TaskApplication,
    TaskDetails,
    fetchTaskFromAPI,
    getTaskStatusFromAPI,
    applyTaskToAPI,
} from "../services/pumpRoomService";
import { createTildaProfileMessageHandler } from "../lib/tildaProfile";

interface TaskPageProps {
    repoName: string;
    taskName: string;
}

const TaskPage: React.FC<TaskPageProps> = ({
    repoName,
    taskName
}) => {
    const [result, setResult] = useState<TaskDetails | null>(null);
    const [loadingResult, setLoadingResult] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [initialTask, setInitialTask] = useState<PumpingTask | null>(null);
    const [currentTask, setCurrentTask] = useState<PumpingTask | null>(null);
    const [user, setUser] = useState<string | null>(null);

    useEffect(() => {
        let isMounted = true;

        const handleTildaProfileMessage =
            createTildaProfileMessageHandler(setUser);
        window.addEventListener("message", handleTildaProfileMessage);

        const loadTask = async () => {
            try {
                setLoadingResult(true);
                setError(null);
                const initialTask = await fetchTaskFromAPI(repoName, taskName);

                if (isMounted) {
                    // Only update state if still mounted
                    setInitialTask(initialTask);
                    setCurrentTask(initialTask);
                }
            } catch (error) {
                console.error("Error loading task:", error);
                if (isMounted) {
                    setError("Не удалось загрузить задание. Попробуйте обновить страницу.");
                }
            } finally {
                if (isMounted) {
                    setLoadingResult(false);
                }
            }
        };

        if (taskName && repoName) {
            loadTask();
        }

        return () => {
            isMounted = false;
            window.removeEventListener("message", handleTildaProfileMessage);
        };
    }, [taskName, repoName, setInitialTask, setCurrentTask]);

    const applyTask = async (selectedFile: string | null) => {
        const taskApplication: TaskApplication = {
            name: taskName,
            repository: repoName,
            files: currentTask?.files || [],
            selected_file_path: selectedFile,
            user: user,
        };
        setResult(null);
        setLoadingResult(true);
        try {
            setError(null);
            const response = await applyTaskToAPI(taskApplication);
            setResult(response);
            console.log("Application result", response);
            const taskId = response.task_id;
            let taskDetails: TaskDetails | null = response;
            let attempts: number = 0;
            const interval: number = 1000; // in ms
            const timeout: number = 5 * 60 * 1000; // 5 minutes
            const maxAttempts: number = timeout / interval;

            while (!taskDetails?.ready && attempts < maxAttempts) {
                try {
                    taskDetails = await getTaskStatusFromAPI(repoName, taskId);
                    if (taskDetails.ready) {
                        break;
                    }
                } catch (error) {
                    console.error("Error fetching task details:", error);
                    break;
                }
                attempts++;
                await new Promise((resolve) => setTimeout(resolve, interval));
            }

            if (taskDetails) {
                console.log("Final task details", taskDetails);
                setResult(taskDetails);
            } else {
                setError(
                    "Не удалось получить результат выполнения задачи. Попробуйте позже."
                );
            }
        } catch (error) {
            setResult(null);
            console.error("Error applying task:", error);
            setError("Не удалось выполнить задачу. Попробуйте позже.");
        } finally {
            setLoadingResult(false);
        }
    };

    if (currentTask) {
        if (currentTask.backend === "judge0") {
            return (
                <Box className="app" px={1} style={{ height: "100%" }}>
                    <IDE
                        result={result}
                        loadingResult={loadingResult}
                        currentTask={currentTask}
                        setCurrentTask={setCurrentTask}
                        initialTask={initialTask}
                        error={error}
                        setError={setError}
                        applyTask={applyTask}
                    />
                </Box>
            );
        } else if (currentTask.backend === "gpt") {
            return (
                <Box px={1} className="app" style={{ height: "100%" }}>
                    <GPTChat
                        result={result}
                        loadingResult={loadingResult}
                        currentTask={currentTask}
                        setCurrentTask={setCurrentTask}
                        initialTask={initialTask}
                        error={error}
                        setError={setError}
                        applyTask={applyTask}
                    />
                </Box>
            );
        } else {
            return <div>Backend not supported</div>;
        }
    } else {
        if (!error) {
            return (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100vh"
                >
                    <CircularProgress color="secondary" />
                </Box>
            );
        } else {
            return (
                <Container>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100vh"
                    >
                        <Alert severity="error">{error}</Alert>
                    </Box>
                </Container>
            );
        }
    }
};

export default TaskPage;
