// src/components/ResultPanel.tsx
import React from "react";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Chip, CircularProgress } from "@mui/material";
import AnsiToHtml from "ansi-to-html";
import { TaskDetails } from "../services/pumpRoomService";

interface ResultPanelProps {
    result: TaskDetails | null;
    loadingResult: boolean;
    pristine: boolean;
}

const convert = new AnsiToHtml({
    fg: "#000000", // Default foreground color
    bg: "#FFFFFF", // Default background color if needed
});

const ResultPanel: React.FC<ResultPanelProps> = ({
    result,
    loadingResult,
    pristine,
}) => {
    return (
        <Box paddingTop={2} paddingX={2} style={{ overflow: "auto" }}>
            <Grid container>
                <Grid>
                    <Typography variant="h6">Результат выполнения</Typography>
                </Grid>
                <Grid marginLeft={1}>
                    {result && result.status !== "Applied" && (
                        <Chip
                            label={result?.status}
                            color={
                                result?.status === "Accepted"
                                    ? "success"
                                    : "error"
                            }
                            size="small"
                        />
                    )}
                </Grid>
            </Grid>
            {!result ? (
                <>
                    {pristine && (
                        <Typography
                            variant="body2"
                            component={"div"}
                            marginTop={2}
                        >
                            Запустите что-нибудь, чтобы увидеть результат
                        </Typography>
                    )}
                </>
            ) : (
                <>
                    {result.ready && (
                        <Box
                            style={{
                                // height: "100%",
                                // overflow: "auto",
                            }}
                        >
                            {result.message && (
                                <Typography variant="body2" component={"div"}>
                                    <strong>Сообщение:</strong> {result.message}
                                </Typography>
                            )}
                            {result.stdout && (
                                <Typography variant="body2" component={"pre"}>
                                    <strong>Вывод (stdout):</strong>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: convert.toHtml(
                                                result.stdout
                                            ),
                                        }}
                                        style={{
                                            fontFamily: "monospace",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    />
                                </Typography>
                            )}
                            {(result.stderr || result.compile_output) && (
                                <Typography
                                    variant="body2"
                                    component={"div"}
                                    color="error"
                                >
                                    <strong>Ошибка (stderr):</strong>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: convert.toHtml(
                                                result.stderr ||
                                                    result.compile_output ||
                                                    ""
                                            ),
                                        }}
                                        style={{
                                            fontFamily: "monospace",
                                            whiteSpace: "pre-wrap",
                                        }}
                                    />
                                </Typography>
                            )}
                        </Box>
                    )}
                </>
            )}

            {loadingResult && !pristine && <CircularProgress sx={{ m: 2 }} />}
        </Box>
    );
};

export default ResultPanel;
