import React, { useState, useEffect } from "react";
import {
    Typography,
    Paper,
    CircularProgress,
    Backdrop,
    Box,
    Button,
} from "@mui/material";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import { TreeViewBaseItem, TreeViewItemId } from "@mui/x-tree-view/models";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { TaskFile, PumpingTask } from "../services/pumpRoomService";

interface FilePanelProps {
    setSelectedFile: (selectedFile: string) => void;
    selectedFile: string | null;
    currentTask: PumpingTask | null;
}

const getAllItemsWithChildrenItemIds = (items: TreeViewBaseItem[]) => {
    const itemIds: TreeViewItemId[] = [];
    const registerItemId = (item: TreeViewBaseItem) => {
        if (item.children?.length) {
            itemIds.push(item.id);
            item.children.forEach(registerItemId);
        }
    };

    items.forEach(registerItemId);

    return itemIds;
};

const FilePanel: React.FC<FilePanelProps> = ({
    setSelectedFile,
    selectedFile,
    currentTask,
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    const getTaskFile = (filePath: string): TaskFile | undefined => {
        if (!currentTask || !currentTask.files) {
            return;
        }
        return currentTask.files.find((file) => file.path === filePath);
    };

    function createTree(files: string[]): TreeViewBaseItem[] {
        const tree: TreeViewBaseItem[] = [];

        files.forEach((file) => {
            const taskFile: TaskFile | undefined = getTaskFile(file);
            const parts = file.split("/");
            let currentLevel: TreeViewBaseItem[] | undefined = tree;

            parts.forEach((part, index) => {
                let existingItem: TreeViewBaseItem | undefined;
                if (currentLevel) {
                    existingItem = currentLevel.find(
                        (item) => item.label === part
                    );
                } else {
                    existingItem = undefined;
                }
                if (!existingItem) {
                    existingItem = {
                        id: parts.slice(0, index + 1).join("/"),
                        label: part,
                        children: [],
                    };
                    if (currentLevel) {
                        currentLevel.push(existingItem);
                    }
                }
                currentLevel = existingItem.children;
            });
        });

        return tree;
    }

    let ITEMS = createTree(currentTask?.files.map((file) => file.path) || []);

    useEffect(() => {
        if (currentTask) {
            setLoading(false);
            if (!selectedFile && currentTask.files.length > 0) {
                if (
                    currentTask.selected_file &&
                    currentTask.files.find(
                        (file) => file.path === currentTask.selected_file
                    )
                ) {
                    setSelectedFile(currentTask.selected_file);
                } else {
                    setSelectedFile(currentTask.files[0].path);
                }
            }
        }
    }, [currentTask, setSelectedFile]);

    const handleFileSelect = (
        event: React.SyntheticEvent,
        fileId: string | null
    ) => {
        if (fileId) {
            setSelectedFile(fileId);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (!currentTask) {
        return (
            <Paper
                square
                elevation={3}
                style={{ padding: "16px", height: "100%" }}
            >
                <Typography variant="h6" gutterBottom>
                    Нет загруженной задачи
                </Typography>
            </Paper>
        );
    }

    return (
        <Paper
            square
            style={{
                paddingLeft: "16px",
                paddingRight: "16px",
                // paddingTop: "16px",
                
                height: "100%",
                display: "flex", // Added
                flexDirection: "column", // Added
            }}
        >
            <Typography variant="h6" gutterBottom paddingTop={"16px"}>
                Файлы
            </Typography>
            <div style={{ flexGrow: 1, overflow: "auto" }}>
                <RichTreeView
                    items={ITEMS}
                    slots={{ item: TreeItem2 }}
                    defaultExpandedItems={getAllItemsWithChildrenItemIds(ITEMS)}
                    defaultSelectedItems={ITEMS[0].id}
                    selectedItems={selectedFile}
                    onSelectedItemsChange={handleFileSelect}
                />
            </div>
            {error && (
                <Backdrop open={true} style={{ zIndex: 1300, color: "#fff" }}>
                    <Box
                        textAlign="center"
                        bgcolor="error.main"
                        p={3}
                        borderRadius={2}
                    >
                        <Typography variant="h6">{error}</Typography>
                        <Button onClick={() => setError(null)} color="inherit">
                            Закрыть
                        </Button>
                    </Box>
                </Backdrop>
            )}
        </Paper>
    );
};

export default FilePanel;
