import axios from 'axios';

const API_BASE_URL: string = process.env.REACT_APP_API_BASE_URL || 'https://pump-room-api.inzhenerka-cloud.com';

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  // timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Optionally add interceptors for request or response
apiClient.interceptors.request.use((config) => {
  // Modify config, e.g., attach tokens, logging, etc.
  return config;
});

export default apiClient;
