import React from "react";
import { Backdrop, Box, Typography, Button } from "@mui/material";

interface ErrorProps {
    errorText: string;
    onClose: () => void;
}

const Error: React.FC<ErrorProps> = ({ errorText, onClose }) => {
    return (
        <Backdrop open={true} style={{ zIndex: 1300, color: "#fff" }}>
            <Box textAlign="center" bgcolor="error.main" p={3} borderRadius={2}>
                <Typography variant="h6">{errorText}</Typography>
                <Button onClick={onClose} color="inherit">
                    Закрыть
                </Button>
            </Box>
        </Backdrop>
    );
};

export default Error;
