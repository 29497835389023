import React from "react";

export interface TildaProfile {
    name: string;
    login: string;
    lang: string;
    projectid: string;
}

export const createTildaProfileMessageHandler = (
    setUser: React.Dispatch<React.SetStateAction<string | null>>
) => {
    return (event: MessageEvent) => {
        // Verify the origin of the message
        if (event.origin !== "https://inzhenerka.tech") {
            console.log("Unsupported origin:", event.origin);
            return;
        }
        // Check if the message is the response from the parent window
        if (
            event.data &&
            event.data.type === "getTildaProfile"
        ) {
            const profile: TildaProfile = event.data.data;
            setUser(profile.login);
        }
    };
}
