import apiClient from './apiClient';

export interface TaskFile {
    name: string;
    path: string;
    is_binary: boolean;
    content: string;
    readonly: boolean;
}

type BackendType = 'judge0' | 'gpt';
type LanguageType = 'text' | 'rust' | 'java' | 'javascript' | 'typescript' | 'python' | 'go' | 'bash';
type FrameworkType = 'playwright' | 'maven' | 'rustc' | 'python_multi' | 'cargo_test' | 'teacher';


export interface PumpingTask {
    id: number;
    name: string;
    repository: string;
    backend: BackendType;
    language: LanguageType;
    framework: FrameworkType | null;
    files: TaskFile[];
    selected_file: string | null;
    read_only: boolean;
}

export interface TaskWithResult {
    initial_task: PumpingTask;
    saved_task: PumpingTask | null;
    saved_result: TaskDetails | null;
}

export interface TaskApplication {
    name: string;
    repository: string;
    files: TaskFile[];
    selected_file_path: string | null;
    user: string | null;
}

export interface TaskDetails {
    task_id: string;
    status: string | null;
    ready?: boolean;
    stdout?: string | null;
    stderr?: string | null;
    compile_output?: string | null;
    message?: string | null;
    exit_code?: number | null;
};

export const fetchTaskFromAPI = async (repoName: string, taskName: string): Promise<PumpingTask> => {
    try {
        const response = await apiClient.get(`/get_task`, {
            params: { repo_name: repoName, task_name: taskName },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching task from API:', error);
        throw error;
    }
};

export const applyTaskToAPI = async (taskApplication: TaskApplication): Promise<TaskDetails> => {
    try {
        const response = await apiClient.post(`/apply_task`, taskApplication);
        return response.data;
    } catch (error) {
        console.error('Error applying task to API:', error);
        throw error;
    }
};

export const getTaskStatusFromAPI = async (repoName: string, taskId: string): Promise<TaskDetails> => {
    try {
        const response = await apiClient.get(`/get_task_status`, {
            params: {
                repo_name: repoName,
                task_id: taskId
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error getting task status from API:', error);
        throw error;
    }
};
