import React from "react";
import "./App.css";
import HomePage from "./components/HomePage";
import TaskPage from "./components/TaskPage";

const App: React.FC = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const repoName = searchParams.get("repo_name");
    const taskName = searchParams.get("task_name");

    if (repoName && taskName) {
        return <TaskPage repoName={repoName} taskName={taskName} />;
    } else {
        return <HomePage />;
    }
};

export default App;
