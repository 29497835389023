import React, { useState, useEffect } from "react";
import {
    Button,
    IconButton,
    Box,
    CircularProgress,
    Alert,
    Avatar,
    TextField,
    Tooltip,
    Paper,
    Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { purple } from "@mui/material/colors";
import SendIcon from "@mui/icons-material/Send";
import ReactMarkdown from "react-markdown";
import { PumpingTask, TaskFile, TaskDetails } from "../services/pumpRoomService";

interface GPTChatProps {
    result: TaskDetails | null;
    loadingResult: boolean;
    currentTask: PumpingTask | null;
    setCurrentTask: React.Dispatch<React.SetStateAction<PumpingTask | null>>;
    initialTask: PumpingTask | null;
    error: string | null;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    applyTask: (selectedFile: string | null) => Promise<void>;
}

const GPTChat: React.FC<GPTChatProps> = ({
    result,
    loadingResult,
    currentTask,
    setCurrentTask,
    initialTask,
    error,
    setError,
    applyTask,
}) => {
    const [openFileDrawer, setOpenFileDrawer] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [pristine, setPristine] = useState<boolean>(true);
    const [textError, setTextError] = useState<boolean>(false);

    useEffect(() => {
        if (currentTask) {
            if (!selectedFile && currentTask.files.length > 0) {
                if (
                    currentTask.selected_file &&
                    currentTask.files.find(
                        (file) => file.path === currentTask.selected_file
                    )
                ) {
                    setSelectedFile(currentTask.selected_file);
                } else {
                    setSelectedFile(currentTask.files[0].path);
                }
            }
        }
    }, [currentTask, setSelectedFile]);

    const getSelectedTaskFile = (): TaskFile | undefined => {
        if (!currentTask || !currentTask.files) {
            return;
        }
        return currentTask.files.find((file) => file.path === selectedFile);
    };

    const handleSendMessage = async () => {
        const selectedTaskFile = getSelectedTaskFile();
        if (!selectedTaskFile?.content.trim()) {
            setTextError(true);
            return;
        }
        setTextError(false);
        await applyTask(selectedFile);
    };

    const handleFileChange = (value: string) => {
        setTextError(false);
        if (!currentTask || !currentTask.files) {
            return;
        }
        const updatedFiles = currentTask.files.map((file) => {
            if (file.path === selectedFile) {
                return { ...file, content: value };
            }
            return file;
        });
        setCurrentTask({ ...currentTask, files: updatedFiles });
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            gap={2}
            py={2}
            height="100%"
            width="100%"
        >
            <Grid
                container
                spacing={1}
                justifyContent="center"
                style={{ width: "100%" }}
            >
                <Grid
                    size={{ xs: 2, md: 1 }}
                    justifyContent="center"
                    display={"flex"}
                >
                    <Avatar alt="Student" sx={{ width: 60, height: 60 }} />
                </Grid>
                <Grid size={{ xs: 8, md: 10 }}>
                    <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        label="Write your message"
                        value={getSelectedTaskFile()?.content || ""}
                        onChange={(e) => handleFileChange(e.target.value)}
                        error={textError}
                        helperText={textError ? "Message cannot be empty" : ""}
                        maxRows={5}
                    />
                </Grid>
                <Grid size={{ xs: 2, md: 1 }} mt={"auto"}>
                    <Button
                        variant="contained"
                        color="primary"
                        endIcon={<SendIcon />}
                        onClick={handleSendMessage}
                        fullWidth
                        sx={{ display: { xs: "none", sm: "inline-flex" } }}
                    >
                        Send
                    </Button>
                    <IconButton
                        color="primary"
                        aria-label="Send"
                        onClick={handleSendMessage}
                        sx={{ display: { xs: "inline-flex", sm: "none" } }}
                    >
                        <SendIcon />
                    </IconButton>
                </Grid>
            </Grid>
            {!result && loadingResult && (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <CircularProgress color="secondary" />
                </Box>
            )}
            {result && (
                <Grid container spacing={1} justifyContent="center">
                    <Grid size={{ xs: 2, md: 1 }}></Grid>
                    <Grid size={{ xs: 8, md: 10 }}>
                        {result.status !== null && (
                            <Alert
                                variant="outlined"
                                severity={
                                    result.status === "Accepted"
                                        ? "success"
                                        : "error"
                                }
                                sx={{ maxHeight: 150 }}
                            >
                                {result.message}
                            </Alert>
                        )}
                        {result.status === null && (
                            <Paper
                                variant="outlined"
                                sx={{
                                    maxHeight: 250,
                                    overflowY: "scroll",
                                    paddingX: 1,
                                    wordBreak: "break-word",
                                    overflowWrap: "break-word",
                                }}
                            >
                                <ReactMarkdown>{result.message}</ReactMarkdown>
                            </Paper>
                        )}
                    </Grid>
                    <Grid
                        size={{ xs: 2, md: 1 }}
                        justifyContent="center"
                        display={"flex"}
                    >
                        <Tooltip title="Doodoo AI" placement="left">
                            <Avatar
                                alt="Teacher"
                                sx={{
                                    width: 60,
                                    height: 60,
                                    bgcolor: purple[100],
                                }}
                                src="/doodoo.png"
                            />
                        </Tooltip>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};

export default GPTChat;
