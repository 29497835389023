// Actions.tsx
import React from "react";
import { Stack, Button } from "@mui/material";
import { PumpingTask } from "../services/pumpRoomService";

interface ActionsProps {
    currentTask: PumpingTask | null;
    initialTask: PumpingTask | null;
    handleRunCode: () => Promise<void>;
    setCurrentTask: React.Dispatch<React.SetStateAction<PumpingTask | null>>;
}

const Actions: React.FC<ActionsProps> = ({
    currentTask,
    initialTask,
    handleRunCode,
    setCurrentTask,
}) => {
    return (
        <Stack
            id="actions"
            direction="row"
            spacing={2}
            className="actions"
            style={{ paddingLeft: 16 }}
        >
            <Button
                variant="contained"
                color="primary"
                style={{
                    textTransform: "none",
                }}
                onClick={handleRunCode}
            >
                {currentTask?.framework === "playwright"
                    ? "> npx playwright test"
                    : currentTask?.framework === "maven"
                    ? "> mvn test"
                    : currentTask?.framework === "rustc"
                    ? "> rustc main.rs && ./main"
                    : currentTask?.framework === "python_multi"
                    ? "> python main.py"
                    : currentTask?.framework === "cargo_test"
                    ? "> cargo test"
                    : "ЗАПУСТИТЬ"}
            </Button>
            <Button
                variant="outlined"
                onClick={() => setCurrentTask(initialTask)}
            >
                Сбросить код
            </Button>
        </Stack>
    );
};

export default Actions;
