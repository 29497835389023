import React, { useState } from "react";
import {
    Box,
    Typography,
    Paper,
    Chip,
    IconButton,
    Drawer,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import MenuIcon from "@mui/icons-material/Menu";
import FilePanel from "./FilePanel";
import CodeEditor from "./CodeEditor";
import ResultPanel from "./ResultPanel";
import Actions from "./Actions";
import Error from "./Error";
import {
    PumpingTask,
    TaskFile,
    TaskDetails,
} from "../services/pumpRoomService";

interface IDEProps {
    result: TaskDetails | null;
    loadingResult: boolean;
    currentTask: PumpingTask | null;
    setCurrentTask: React.Dispatch<React.SetStateAction<PumpingTask | null>>;
    initialTask: PumpingTask | null;
    error: string | null;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
    applyTask: (selectedFile: string | null) => Promise<void>;
}

const IDE: React.FC<IDEProps> = ({
    result,
    loadingResult,
    currentTask,
    setCurrentTask,
    initialTask,
    error,
    setError,
    applyTask,
}) => {
    const [openFileDrawer, setOpenFileDrawer] = useState(false);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [pristine, setPristine] = useState<boolean>(true);

    const getSelectedTaskFile = (): TaskFile | undefined => {
        if (!currentTask || !currentTask.files) {
            return;
        }
        return currentTask.files.find((file) => file.path === selectedFile);
    };

    const toggleFileDrawer = (isOpen: boolean) => () => {
        setOpenFileDrawer(isOpen);
    };

    const handleRunCode = async () => {
        setPristine(false);
        await applyTask(selectedFile);
    };

    return (
        <>
            <Grid
                container
                spacing={0}
                paddingY={1}
                style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <Grid
                    size={{ xs: 3, lg: 2 }}
                    sx={{ display: { xs: "none", md: "flex" } }}
                    style={{
                        height: "100%",
                        flexDirection: "column",
                    }}
                >
                    <Box sx={{ marginRight: 1, height: "100%" }}>
                        <FilePanel
                            setSelectedFile={setSelectedFile}
                            selectedFile={selectedFile}
                            currentTask={currentTask}
                        />
                    </Box>
                </Grid>
                <Grid
                    size={{ xs: 12, md: 9, lg: 10 }}
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <Paper
                        square
                        // sx={{ mx: 1 }}
                        style={{
                            height: "100%",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <div
                            style={{
                                flexGrow: currentTask?.read_only ? 1 : 3,
                                display: "flex",
                                flexDirection: "column",
                                overflow: "hidden",
                            }}
                        >
                            <div
                                style={{
                                    marginLeft: 16,
                                    marginTop: 24,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <IconButton
                                    sx={{
                                        display: {
                                            xs: "block",
                                            md: "none",
                                        },
                                        pt: 2,
                                    }}
                                    color="inherit"
                                    aria-label="Файлы"
                                    onClick={toggleFileDrawer(true)}
                                >
                                    <MenuIcon />
                                </IconButton>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    {selectedFile && (
                                        <Typography variant="h6" sx={{ ml: 1 }}>
                                            {currentTask?.read_only
                                                ? "Просмотр"
                                                : "Редактор"}
                                            : {selectedFile}
                                        </Typography>
                                    )}
                                    {selectedFile &&
                                        getSelectedTaskFile()?.readonly && (
                                            <Chip
                                                label="read-only"
                                                color="error"
                                                size="small"
                                                sx={{ marginLeft: 1 }}
                                            />
                                        )}
                                </div>
                            </div>
                            <CodeEditor
                                selectedFile={selectedFile}
                                currentTask={currentTask}
                                setCurrentTask={setCurrentTask}
                            />
                        </div>
                        {/* Result Panel */}
                        {!currentTask?.read_only && (
                            <div
                                style={{
                                    flexGrow: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    overflow: "hidden",
                                    minHeight: "45%",
                                    maxHeight: "45%",
                                }}
                            >
                                <Actions
                                    currentTask={currentTask}
                                    initialTask={initialTask}
                                    handleRunCode={handleRunCode}
                                    setCurrentTask={setCurrentTask}
                                />
                                <ResultPanel
                                    result={result}
                                    loadingResult={loadingResult}
                                    pristine={pristine}
                                />
                            </div>
                        )}
                    </Paper>
                </Grid>
            </Grid>

            <Drawer
                anchor="left"
                open={openFileDrawer}
                onClose={toggleFileDrawer(false)}
            >
                <Box
                    sx={{ width: 250, height: "100%" }}
                    role="presentation"
                    onClick={toggleFileDrawer(false)}
                    onKeyDown={toggleFileDrawer(false)}
                >
                    <FilePanel
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        currentTask={currentTask}
                    />
                </Box>
            </Drawer>
            {error && (
                <Error errorText={error} onClose={() => setError(null)} />
            )}
        </>
    );
};

export default IDE;
